import { graphql } from 'gatsby'
import * as React from 'react'

import Layout from '../components/layout'

const NotFoundPage = ({ data }: { data: { sitePage: { path: string } } }): JSX.Element => {
  const sitePath = data.sitePage.path

  return (
    <Layout>
      <div id="notfound">
        <h2>Page Not Found</h2>
        <p>
          Oops, we couldn&apos;t a page at this location: <span>{sitePath}</span>!
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    sitePage {
      path
    }
  }
`
